.ComingSoon {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--blackColor);
    font-family: var(--primaryFont);
    padding-top: 7vh;
    opacity: 0.8;
}

.ComingSoon img {
    max-width: 70%;
}

.ComingSoon .ComingSoon__title {
    font-weight: 300;
}

.ComingSoon p {
    color: var(--mono4);
}