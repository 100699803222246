.shopAndAddressPicker {
  display: none;
}

.header__selectShop :global .header__mobile {
  display: block;
}
@media (min-width: 768px) {
  .shopAndAddressPicker {
    display: block;
  }
}
