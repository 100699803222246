.container {
  padding: 8px 16px;
}
.searchForm {
  width: 100%;
  margin: 0;
}
.container input {
  width: 100%;
  max-width: 450px;
  flex-grow: 1;
}
.container button, .container input {
  /* border: none !important; */
}
.searchForm label {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .container {
    display: flex;
  }
}
