.mapMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
        z-index: 1;
    }
}

.googleMap {
    max-width: 500px;
}

.deliveryIcon {
    max-width: 35px;
    max-height: 35px;
}