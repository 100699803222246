.ant-btn {
  padding: 12px;
  height: auto;
}

.ant-btn-primary {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: var(--primaryColor);
  border-color: var(--primaryColor);
}
<style>
.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  text-decoration: none;
  background: #fff;
}
<style>
.ant-btn:hover, .ant-btn:focus {
  color: var(--primaryColor);
  background: #fff;
  border-color: var(--primaryColor);
}
