.component {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--lightBg);
  box-shadow:var(--shadow-1);
  justify-content: space-between;
  z-index: 1;
}

.iconLink {
  padding: 8px;
  font-size: 24px;
  color: var(--colorBlack);
  position: relative;
  transition: all 0.3s;
}

.cartLink :global .content{
  margin-left: 0 !important;
  min-width: 20px;
}
.cartLink :global .ant-badge-count {
  top: -8px !important;
  right: 8px;
}
@media screen and (min-width: 768px) {
  .component {
    display: none;
  }
}
