.tab_container {
  padding: 0 16px;
}

.skelton {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 768px) {
  .tab_container {
    padding: 0;
  }
}
