.wrapper {
    margin: 15px 0;
    font-size: 14px;
    /* display: flex; */
}

.wrapper label {
    margin-right: 5px;
    min-width: 240px;
    display: inline-block;
    color: var(--blackColor);
    margin-bottom: var(--wcGutterBasic);
}

.wrapper input,
.wrapper input[type='text'] {
    border: none;
    outline: none;
    padding: 8px 12px;
    margin-bottom: 0;
    color: #132437;
    font-size: 14px;
    font-weight: 300;
}

.inputGroup {
    background: var(--offWhiteColor);
    border-radius: 7px;
    border: var(--border-1);
    margin-bottom: 8px;
}
.inputGroup button {
    border: none;
    margin-bottom: 0;
    opacity: 0.4;
    flex-basis: 24px;
    margin-top: 0;
    font-size: 20px;
    padding: 10px;
}

.wrapper input:focus {
    border-color: var(--primaryColor);
}

.wrapper span {
    display: flex;
    justify-content: space-between;
}

@media screen (min-width: 768px) {
    .wrapper label {
        display: block;
    }
}
