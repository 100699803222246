.shopItem {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 120px;
  width: 100%;
  border-radius: 8px;
  box-shadow: var(--shadow-4);
}

.data {
  padding: 8px;
}

.taking {
  color: var(--greenColor)
}

.not_taking {
  color: var(--dangerColor);
}
