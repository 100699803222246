.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primaryColor);
}

.ant-tabs-ink-bar {
  background:  var(--primaryColor);
}

.ant-drawer-content-wrapper {
  min-height: 50vh;
}
